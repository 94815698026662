.navbar {

  width: 100%;
  padding: 20px 50px;
  text-align: center;
  background: #fff;
  color: #fff;
  height: fit-content;
  display: inline-flex;
  align-items: center;
  
  @media (min-width: 1000px) {
    box-shadow: 0 0px 16px 0 rgba(0,0,0,0.2),0 0px 20px 0 rgba(0,0,0,0.19);
  }

  .search {
    
    width: 100%;
    margin-right: 30px;
    position: relative;
    display:none;

    @media (min-width: 1000px) {
      display: inline-flex;
      width: 30%;
      margin-right: auto;
    }

    input {
      background: #F8F8F8;
      border: none;
      padding: 10px 25px;
      border-radius: 7px 0 0 7px;
      font-size: 1em;
      font-weight: 400;
      width: 100%;
    }

    .button {
      background: #3C62D2;
      padding: 10px 15px;
      border-radius: 0 7px 7px 0;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #6987E2;
      }
    }

    .toast {
      width: 100%;
      color: #000;
      background: #fff;
      border-radius: 7px;
      position: absolute;
      top: 0; left: 0;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1),0 5px 10px 0 rgba(0,0,0,0.09);
      z-index: 100;
      overflow: hidden;
      transform: translateY(50px);

      button {
        width: 100%;
        padding: 20px 30px;
        background: #ededed;

        &:hover {
          background: #dfdfdf;
        }
      }

      .results {
        max-height: 200px;
        overflow-y: auto;
        border-bottom: 1px solid #D9D9D9;
        .tokenWrap {
          padding: 20px 30px;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;

          &:not(:last-child){
            border-bottom: 1px solid #D9D9D9;
          }
          
          img,.placeholder {
            width: 20px;
            height: 20px;
            margin-right: 20px;
            border-radius: 7px;
          }

          div {
            display: flex;
            flex-direction: column;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;

            a {
              margin-top: 4px;
              color: #3C62D2;
            }
          }

          .externalLink {
            color: #B9B9B9;
            margin-left: auto;
          }
        }
      }
    }
  }

  .bars {
    color: #717171;
    font-size: 2em;
    cursor: pointer;

    @media (min-width: 1000px) {
      display: none;
    }
  }

  .connect {
    margin-left: auto;
    border-left: 1px solid #E9E9E9;
    padding-left: 30px;
    font-size: 1em;
    font-weight: 400;

    .button {
      background: #3C62D2;
      padding: 10px 30px;
      border-radius: 7px;
      transition: all 0.2s ease-in-out;
      display: flex;

      svg {
        margin-right: 10px;
      }

      &:hover {
        background: #6987E2;
      }
    }
  }

  .address {
    color: #717171;
    font-weight: 300;
    display: inline-flex;
    align-items: center;

    a {
      margin-right: 30px;
    }
  }

}