.view {
  height: 100%;
  width: 100%;
  color: #000;
  position: relative;
  overflow-y: auto;

  .headline {
    width: 100%;
    padding: 30px 30px 0 30px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;

    h2 {
      font-weight: 300;
      font-size: 2em;
      color: #515151;
      margin: 0;
    }

    .button {
      color: #fff;
      margin-left: auto;
      background: #3C62D2;
      padding: 10px 30px;
      border-radius: 7px;
      transition: all 0.2s ease-in-out;
      display: inline-flex;
      align-items: center;

      &:hover {
        background: #6987E2;
      }

      svg {
        margin-right: 10px;
      }
    }
  }

  .dashboard {
    width: 100%;
    padding: 0 15px;
    display: block;

    @media (min-width: 1000px) {
      display: inline-flex;
      flex-wrap: wrap;
    }
  }
  .approvals {
    width: 100%;
    padding: 0 15px;
    display: block;

    @media (min-width: 1000px) {
      display: inline-flex;
      flex-wrap: wrap;
    }
  }
}

.centered {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h2 {
    font-size: 4em;
    color: #515151;
  }
  p {
    font-size: 1.2em;
    color: #717171;
  }
}

table {
  tr {
    td {
      .button {
        width: 100%;
        color: #fff;
        background: #000;
        padding: 10px 15px;
        border-radius: 7px;
        transition: all 0.2s ease-in-out;
      }

      .search {
        width: 100%;
      }

      input {
        background: #F8F8F8;
        border: none;
        padding: 10px 25px;
        border-radius: 7px 0 0 7px;
        font-size: 1em;
        font-weight: 400;
        width: 100%;
        min-width: 200px;
      }

      .edit {
        background: #3C62D2;
        border-radius: 0 7px 7px 0;
        width: fit-content;

        &:hover {
          background: #6987E2;
        }
      }

      .revoke {
        background: #D23C3E;
        min-width: max-content;

        svg {
          margin-right: 10px;
        }

        &:hover {
          background: #E2697D;
        }
      }
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  padding: 20px 0 0 0;

  .controllerWrap {
    margin-left: auto;
    display: flex;

    .controller {
      cursor: pointer;
      font-size: 1.5em;
    }
    
    .pageNumber {
      margin: 0 20px;
    }

    a {
      color: #3C62D2;
    }
  }
}

.high {
  padding: 5px 30px;
  background: rgb(199, 28, 51);
  color: #fff;
  font-weight: 400;
  border-radius: 7px;
  display: flex !important;
  justify-content: center;
}
.medium {
  padding: 5px 30px;
  background: rgb(247, 196, 67);
  color: #fff;
  font-weight: 400;
  border-radius: 7px;
  display: flex !important;
  justify-content: center;
}
.low {
  padding: 5px 30px;
  background: rgb(53, 185, 204);
  color: #fff;
  font-weight: 400;
  border-radius: 7px;
  display: flex !important;
  justify-content: center;
}