.container {
  align-items: center;
  padding: 15px;

  @media (min-width: 1000px) {
    min-width: 25%;
    flex: fit-content;
  }

  .card {
    max-width: 100%;
    display: flex;  
    flex-direction: column;
    background: #fff;
    border-radius: 7px;
    width: 100%;
    margin: 0;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1),0 5px 10px 0 rgba(0,0,0,0.09);
    overflow: hidden;
  
    .titleBar {
      background: #e4e8f5;
      width: 100%;
      padding: 20px;
      color: #3C62D2;
      font-size: 1.1em;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;

      .title {
        margin-right: auto;
      }

      svg {
        color: #B9B9B9;
        font-size: 1.2em;
      }
    }

    .body {
      width: 100%;
      padding: 20px;
      overflow-x: auto;

    }

    table {
      border: 1px solid #D9D9D9;
      width: 100%;
      text-align: left;
      border-collapse: collapse;

      th {
        background: #F7F7F7;
        font-size: 1.1em;
        font-weight: 400;
      }

      td {
        font-size: 1em;
        font-weight: 300;
      }

      

      th, td {
        padding: 20px;
        border: 1px solid #D9D9D9;

        div {
          display: inline-flex;
          align-items: center;

          img {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            border-radius: 7px;
          }

          span {
            margin-left: 20px;
          }
        }

        a {
          color:#3C62D2;
        }
      }
    }
  }
}