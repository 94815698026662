.main {
  width: 100%;
  height: 100%;
  margin: 0;
  background: #F9F9F9;
  display:flex;
}

.view {
  width:100%;
  display: flex;
  flex-direction: column;
  overflow:hidden;
}