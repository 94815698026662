.sidebar {
  height: 100%;
  background: #3C62D2;
  flex-direction: column;
  padding: 30px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 1000px) {
    position: absolute;
    z-index: 999;
    width: 100%;
    transform: translateX(-300%);
    transition: all 0.3s;
  }
  
  @media (min-width: 1000px) {
    transform: auto;
    width: 10%;
    min-width: 250px;
    box-shadow: 0 0px 16px 0 rgba(0,0,0,0.2),0 0px 20px 0 rgba(0,0,0,0.19);
    display: inline-flex;
  }

  h1 {
    font-weight: 400;
    font-size: 1.5em;
    margin: 0 0 20px 0;
  }

  hr {
    margin: 0 0 30px 0;
    width: 100%;
    border: none;
    border-top: 1px solid #6987E2;
  }

  .credits {
    margin-top: auto;
    font-size: 0.8em;

    hr {
      margin-bottom: 20px;
    }

    .links {
      a {
        color: #fff;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .linkContainer {
    display: inline-flex;
    flex-direction: column;

    hr {
      margin: 20px 0;
    }

  }
}

.active {
  @media (max-width: 1000px) {
    display: inline-flex !important;
    position: absolute;
    width: 100%;
    z-index: 999;

    transform: translateX(0);
  }
}