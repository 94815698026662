.linkWrap {
  width: 100%;
  text-align: left;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: 7px;
  color: #6987E2;
  cursor:pointer;

  &:hover {
    color: #fff;
  }

  p {
    margin-left: 20px;
  }

  svg {
    font-size: 1.5em;
  }

  .carat {
    margin-left: auto;
  }
  
}

.selected {
  color: #fff !important;
}